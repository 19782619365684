.quote-slider,
.kpi-box {

  .quote-slider-container {
    position: relative;
    width: 100%;
    background-color: $object-color-primary;
    padding: $box-padding-large;
    padding-top: 10rem;

    .slider-container {
      .swiper {
        width: 100%;
        height: 100%;

        .swiper-wrapper {
          color: $color-text-secondary;

          .swiper-slide {
            text-align: center;
            background: transparent;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;

            img {
              display: block;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }

        .swiper-pagination {
          position: static;
        }

        .swiper-pagination-bullet {
          background: $color-text-light;
          opacity: 0.4;

          &-active {
            opacity: unset;
            background-color: $object-color-secondary;
          }
        }
      }
    }

    .box-container {
      position: absolute;
      top: 0;
      left: 50%;
      padding: 3rem;
      transform: translate(-50%, -50%);
      max-width: 350px;
      background-color: $object-color-purple;
    }
  }

  @include until($tablet) {
    margin-top: 9rem !important;
  }

  @include from($tablet) {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-bottom: unset;

    .quote-slider-wrapper {
      height: 100%;
    }

    .quote-slider-container {
      width: 83%;
      padding: $box-padding-large;
      padding-left: 10.5rem;

      min-height: 500px;

      .box-container {
        top: 50%;
        left: 0;
        transform: translate(-50%, -50%);
      }

      .slider-container {
        display: flex;
        height: 100%;

        .swiper {
          .swiper-pagination {
            position: absolute;
            text-align: right;
          }
        }
      }
    }
  }
}

.kpi-box {
    .quote-slider-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: $color-background-light-gray;

        &.is-background-primary {
            background-color: $color-background-primary;

            a {
                color: currentColor;
            }
        }

        .box-container {
            background-color: $object-color-secondary;
        }
    }

    &.has-image {
        .quote-slider-container {
            @include from($tablet) {
                padding-left: 16rem;
            }

            @include from($desktop) {
                padding-left: 18rem;
            }

            @include until($tablet) {
                padding-top: 18rem;
            }
        }

        .box-container {
            max-width: none;
            width: 400px;
            padding: 0;
            text-align: left;

            @include from($desktop) {
                width: 450px;
            }

            @include until(401px) {
                width: 100vw;
            }
        }
    }
}

@include from($tablet) {
  .kpi-box {
    .quote-slider-container {
      padding-left: 13rem;
    }
  }
}