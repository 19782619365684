.tag {
  display: inline-block;
  padding: $anker-small-y $anker-small-x;
  background-color: #e6e623;
  border-radius: 7px;
  font-family: $font-family-links;
  text-decoration: unset;
  font-size: 16px;
}

.tag-row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;

  @include mobile {
      gap: 0;

      .anker-link {
        margin-top: 0.5rem;

        &:not(:first-of-type) {
            margin-right: 0.5rem;
        }
      }
  }
}

.select.article-select {
 
  font-size: 1rem;
  select {
    background-color: $button-color-light-gray;
    border-color: transparent;
  }

  &::after {
    border-color: $color-text !important;
    color: $color-text;
  }

}

.tag-container {
  display: flex;
  flex-wrap: wrap;
  column-gap: 1rem;

  @include mobile {
      column-gap: 0;
      row-gap: 0;

      .anker-link {
          margin-top: 0.6rem;
          margin-right: 0.6rem;
      }
  }

  row-gap: 0.6rem;
}


