.author-name {
  display: flex;
  align-items: center;
  padding-right: 2rem;        
  .profile-image {
      flex-shrink: 0;
      width: 70px;
      height: 70px; 
      img {
          border-radius: 50%;
      }
  }
}