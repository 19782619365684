.quote {
    font-family: $font-family-bold;
    @include font-scale(20px, 24px);
    font-weight: $font-weight-semibold;
    fill: $color-text;
    line-height: 1.4;
    svg {
        path {
            fill: inherit;
        }
    }
    &.is-white {
        color: $color-text-light;
        fill: $color-text-light;
    }
    &.is-green {
        color: $color-text-secondary;
        fill: $color-text-secondary;
    }
    &.is-green-white {
        svg:nth-of-type(1) {
            path {
                fill: $color-text-secondary;
            }
        }
        svg:nth-of-type(2n) {
            path {
                fill: $color-text-light;
            }
        }
        span:nth-of-type(1) {
            color: $color-text-secondary;
        }
        span:nth-of-type(2n) {
            color: $color-text-light;
        }
    }
}
