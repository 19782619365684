.hero {
  overflow-y: hidden;
    &-body {
        position: relative;
    }

    &.is-large {
        min-height: 90vh;

        .hero-body {
            display: flex;
            align-items: center;
        }
    }

    &.is-halfheight {
        min-height: 60vh;
    }

    @include until($tablet) {
        &.is-small,
        &.is-medium,
        &.is-halfheight,
        &.is-large {
            min-height: 70vh;
            max-height: 75vh;

            .hero-body {
                display: flex;
                align-items: center;
            }
        }
    }
}