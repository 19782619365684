.is-position-relative {
    position: relative;
}

.is-sticky {
  position: sticky;
  top: 0;
}

.is-fixed {
  position: fixed;
  top:0
}

.columns {
  &.is-right {
    justify-content: flex-end;
  }
}

.pt-10 {
    padding-top: 10rem;
}
.width100 {
    width: 100%;
}
.width80 {
    width: 80%;
}
.height100 {
    height: 100%;
}

.width80-tablet {
  @include from($tablet) {
    width: 80%;
  }
}

.max300px {
  max-width: 300px;
}

.max-w100 {
    max-width: 100%;
}

.align-text-center {
    text-align: center;
}
.is-inline-block {
    display: inline-block;
}

.text-trsansform-initial {
    text-transform: initial;
}

.text-decoration-none {
    text-decoration: none;
}

.mt-1-5 {
  margin-top: 1.5rem !important;
}

.mb-1-5 {
    margin-bottom: 1.5rem !important;
}

.mt2rem {
  margin-top: 2rem;
}

.row-gap-1 {
    row-gap: 1rem;
}    

.z-index-2 {
  z-index:2;
}

.z-index-200 {
  z-index: 200;
}

.has-scroll-margin {
  scroll-margin-top: $navbar-height;
}

.is-flex-gap {
  &-06 {
    gap: 0.6rem;
  }
}

.icon {
    position: relative;
    padding-left: calc(0.75rem + 1.5625rem);

    &.map {
        &:before {
            content: url('../../images/map-icon.svg');
            position: absolute;
            left: 0;
            width: 1.5625rem;
            height: 1.5625rem;
        }
    }
}

.is-element-loading {
  position: relative;
  pointer-events: none;
  opacity: 0.5;

  &.has-hidden-children {
      > * {
          opacity: 0;
          transition: opacity 300ms ease-in-out;
      }
  }

  &:after {
      @include loader;
      position: absolute;
      top: calc(50% - 2.5em);
      left: calc(50% - 2.5em);
      width: 5em;
      height: 5em;
      border-width: 0.25em;
  }
}



@include from($tablet) {
    .padding-right-tablet {
        padding-right: 2rem;
    }
    .padding-left-tablet {
        padding-left: 2rem;
    }

    .align-item-right-tablet {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }
    .align-item-left-tablet {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .align-text-right-tablet {
        text-align: right;
    }
    .align-text-left-tablet {
        text-align: left;
    }
    .width80-tablet {
        width: 80%;
    }
    .mt-7-tablet {
      margin-top: 4rem;
    }
    .mt-8-tablet {
      margin-top: 5rem;
    }
    .mt-9-tablet {
      margin-top: 6rem;
    }
    .pl-9-tablet {
      padding-left: 9rem;
    }
    .mb-6-tablet {
      margin-bottom: 3rem;
    }
    .pr-6-tablet {
      padding-right: 3rem;
    }
    .pb-4-tablet {
      padding-bottom: 2rem;
    }
    .margin-left-auto-tablet {
      margin-left: auto;
    }
  }
  
@include until($tablet) {
  .align-text-center-mobile {
    text-align: center;
  }
}

@include until($tablet) {
    .is-reversed-mobile {
        display: flex;
        flex-direction: column-reverse;
    }
}

.is-absolute-bottom-right {
    position: absolute;
    bottom: 0;
    right: 0;
}

.has-border-radius {
    border-radius: 5px;
}

.border-radius-3px {
    border-radius: 3px;
}

.editmode-alert {
  padding: 2rem;
  background-color: $object-color-orange;
}

.editmode-dashed {
  border: 3px dashed $color-text;
}


@include until($desktop) {
    .text-flow {
        & > *:not(.no-flow) + * {
            margin-top: $nfx-text-flow-default-size;
        }

        @each $class, $size in $nfx-text-flow-sizes-mobile {
            &.#{$class} > *:not(.no-flow) + * {
                margin-top: $size;
            }
        }
    }
}