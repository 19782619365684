//raw video
.video-wrapper {
  video {
    width: 100%;
  }
  position: relative;
  width: fit-content;
  &.is-iphone-youtube,
  &.is-iphone-asset {
    .pimcore_editable_video {
      height: 100%;
    }

    iframe, video {
      height: 100%;
    }

    iframe {
      transform: translateX(-38%)
    }

    .editable-wrapper {
      position: absolute;
      top: 16.5%;
      left: 7.95%;
      overflow: hidden;
      width: 53%;
      aspect-ratio: 1/1.85;
    }
  }

  &.is-contained {
    iframe {
      width: 100%;
      transform: unset;
    }
  }

  &.is-imac-youtube,
  &.is-imac-asset {
    .editable-wrapper {
      position: absolute;
      top: 4%;
      left:3%;
      width: 94%;
    }
    iframe,
    video {
      aspect-ratio: 16/9;
      width: 100%;
      height: 65%
    }
  }

  &.is-raw-youtube,
  &.is-raw-asset {
    width: 100%;
    iframe {
      width: 100%
    }
    video {
      max-height: 400px;
    }
  }
}