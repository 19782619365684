.logo-gallery {
    .gallery {
        display: none;
    }

    @include from($tablet) {
        .gallery {
            display: block;
        }
        .marquee {
            display: none;
        }
    }
    img {
      max-height: 120px
    }

    .marquee .swiper-wrapper {
      -webkit-transition-timing-function: linear !important;
      -o-transition-timing-function: linear !important;
      transition-timing-function: linear !important;
    }
}
