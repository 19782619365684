.section {
  position: relative;
  
  &.is-smaller {
    padding: $section-padding-smaller 1.5rem;

    @include from($desktop) {
      padding: $section-padding-smaller 3rem;
    }
  }

  &.is-tiny {
    padding: $section-padding-tiny 1.5rem;

    @include from($desktop) {
      padding: $section-padding-tiny 3rem;
    }
  }

  &.is-jumbo {
    padding: $section-padding;

    @include from($desktop) {
      padding: $section-padding-jumbo 3rem;
    }
  }

  &-content {
    position: relative;
  }

  &.is-top {
    &-medium {
      padding-top: 6.25rem;
    }

    &-small {
      padding-top: 5rem;
    }

    &-smaller {
      padding-top: $section-padding-smaller;
    }

    &-tiny {
      padding-top: $section-padding-tiny;
    }

    &-none {
      padding-top: 0 !important;
    }
  }

  &.is-bottom {
    &-medium {
      padding-bottom: 6.25rem;
    }

    &-small {
      padding-bottom: 5rem;
    }

    &-smaller {
      padding-bottom: $section-padding-smaller;
    }

    &-tiny {
      padding-bottom: $section-padding-tiny;
    }

    &-none {
      padding-bottom: 0 !important;
    }
  }

  &.is-breadcrumbs {
    padding: 6rem 3rem 0 3rem;

    @include until($tablet) {
      padding: 6rem 1.5rem 0 1.5rem;
    }
  }
}

@include until($desktop) {

  .section.is-top-medium,
  .section.is-top-small,
  .section.is-bottom-medium,
  .section.is-bottom-small {
    padding: $section-padding;
  }
}


.sticky-section {
  transition: ease-in-out;
  transition-duration: 0.5s;
  height: 0;
  /* background-color: transparent; */
  z-index: 0;
   color: transparent; 
   display: flex;
   align-items: center;

  &.is-active {
    transition: ease-in-out;
    transition-duration: 0.5s;
    background-color: $color-background-primary;
    color: $color-text-light;
    z-index: 9;
    height: 5.3rem;
    display: flex;
   align-items: center;
  }

}

.job-section {
    .job-block {
        margin-top: 6.25rem;
    }

    .content {
        ul {
            margin-bottom: 1rem;

            li + li {
                margin-top: 1rem;
            }
        }
    }
}