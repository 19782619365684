.fixed-height-header {
    position: relative;
    height: 750px;
    width: 100%;
    justify-content: center;
}

.header-playbutton {
    position: absolute;
    pointer-events: none;
    width: 100px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 200;
    @include until($tablet) {
        top: 25%;
        width: 70px;
    }
}
